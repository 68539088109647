<template>
    <div class="relative bg-white overflow-hidden">
  <div class="max-w-7xl mx-auto">
    <div class="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-6xl lg:w-full lg:pb-28 xl:pb-32">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <Header :logo="logo"/>

      <main class="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
        <div class="sm:text-center lg:text-left">
          <h1 class="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">Experience Unmatched Connectivity With</span>
            <span class="block text-primary xl:inline"> Cloud Wave</span>
          </h1>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-2xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            We regret to inform you that Vue Analytics, your trusted internet service provider, is no longer in business. We understand that many of you relied on Vue Analytics for your internet services, and we appreciate your support over the years.
            In light of this change, we want to ensure that you continue to have access to high-quality internet services and top-notch support. That's why we're excited to introduce you to <span class="block text-primary xl:inline font-extrabold"> Cloud Wave</span>.
          </p>
          <p class="mt-2 text-base text-primary sm:mt-4 sm:text-xl sm:max-w-3xl sm:mx-auto md:mt-5 md:text-2xl lg:mx-0 font-extrabold">
            Why Choose <span class="block text-primary xl:inline font-extrabold"> Cloud Wave</span> for Your Internet Needs?
          </p>
          <p class="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-3xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 ">
            Cloud Wave is your new destination for reliable internet services and exceptional customer support. We understand the importance of staying connected in today's digital world. With Cloud Wave, you can expect:

            <ul class="max-w-md lg:mx-0 sm:mx-auto space-y-1 text-gray-500 list-none list-inside mt-4 w-full">
              <li>
              <span class="block text-primary xl:inline font-bold">High-Quality Internet</span> 
              <p>Enjoy lightning-fast, dependable internet connectivity that caters to your specific requirements.</p>
              </li>

              <li>
              <span class="block text-primary xl:inline font-bold">Outstanding Customer Support</span> 
              <p>Our dedicated support team is available 24/7 to assist you with any questions or concerns you may have.</p>
              </li>

              <li>
              <span class="block text-primary xl:inline font-bold">Customized Solutions</span> 
              <p>Cloud Wave offers a range of internet plans to meet your unique needs, whether you're a business or an individual.</p>
              </li>
            </ul>
            
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div class="rounded-md shadow">
              <a href="mailto:hello@cw.co.ke?subject=Switching%20From%20Vue&body=Hello%2C%0A%0AI%20want%20to%20make%20the%20switch%20from%20Vue%20Analytics.%20%0A%0AMy%20Information%20is%20as%20follows%3B%0A%0AName%3A%20%0APhone%20Number%3A%0AVue%20Account%20Number%3A%0A%0A%0AThanks.%0A%0AKind%20Regards%2C%0A%0A%5BYour%20Name%5D%0A%0A%0A%0A%0A%0A" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondary hover:bg-primary md:py-4 md:text-lg md:px-10">
                Coming This November, Reserve Now
              </a>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
  <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/3">
    <img class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full" src="https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1055&q=80" alt="">
  </div>
</div>
<Footer :logo="logo" />
</template>

<script>
import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      collapsed: false,
      logo: require(`@/assets/images/logo.png`)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
